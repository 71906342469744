@font-face {
  font-family: 'icons';
  src: url('./icons.eot?bda67c0b299a136f85622472f8147673?#iefix') format('embedded-opentype'),
    url('./icons.woff2?bda67c0b299a136f85622472f8147673') format('woff2'),
    url('./icons.woff?bda67c0b299a136f85622472f8147673') format('woff'),
    url('./icons.ttf?bda67c0b299a136f85622472f8147673') format('truetype'),
    url('./icons.svg?bda67c0b299a136f85622472f8147673#icons') format('svg');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accounting:before {
  content: '\f101';
}

.icon-activity-icon:before {
  content: '\f102';
}

.icon-add-user:before {
  content: '\f103';
}

.icon-add:before {
  content: '\f104';
}

.icon-admin:before {
  content: '\f105';
}

.icon-administration:before {
  content: '\f106';
}

.icon-arrow-left:before {
  content: '\f107';
}

.icon-arrow-right:before {
  content: '\f108';
}

.icon-auth-post:before {
  content: '\f109';
}

.icon-award:before {
  content: '\f10a';
}

.icon-bisa-posting:before {
  content: '\f10b';
}

.icon-bisa:before {
  content: '\f10c';
}

.icon-blm-butane-specification:before {
  content: '\f10d';
}

.icon-block:before {
  content: '\f10e';
}

.icon-calendar:before {
  content: '\f10f';
}

.icon-capacity-release:before {
  content: '\f110';
}

.icon-capacity:before {
  content: '\f111';
}

.icon-carat-down:before {
  content: '\f112';
}

.icon-carat-left:before {
  content: '\f113';
}

.icon-carat-right:before {
  content: '\f114';
}

.icon-carat-up:before {
  content: '\f115';
}

.icon-chalkboard-user:before {
  content: '\f116';
}

.icon-changes:before {
  content: '\f117';
}

.icon-charge-type:before {
  content: '\f118';
}

.icon-check:before {
  content: '\f119';
}

.icon-chev-left:before {
  content: '\f11a';
}

.icon-chev-right:before {
  content: '\f11b';
}

.icon-circle-cross:before {
  content: '\f11c';
}

.icon-clock:before {
  content: '\f11d';
}

.icon-clone:before {
  content: '\f11e';
}

.icon-close-lg:before {
  content: '\f11f';
}

.icon-close-small:before {
  content: '\f120';
}

.icon-close:before {
  content: '\f121';
}

.icon-collapse:before {
  content: '\f122';
}

.icon-contact:before {
  content: '\f123';
}

.icon-contracts:before {
  content: '\f124';
}

.icon-customer-activities:before {
  content: '\f125';
}

.icon-data-sync-lock:before {
  content: '\f126';
}

.icon-data-sync:before {
  content: '\f127';
}

.icon-dead:before {
  content: '\f128';
}

.icon-delete:before {
  content: '\f129';
}

.icon-department-contacts:before {
  content: '\f12a';
}

.icon-dollar:before {
  content: '\f12b';
}

.icon-double-arrow-up:before {
  content: '\f12c';
}

.icon-download:before {
  content: '\f12d';
}

.icon-drought:before {
  content: '\f12e';
}

.icon-edit:before {
  content: '\f12f';
}

.icon-entity-management:before {
  content: '\f130';
}

.icon-entity:before {
  content: '\f131';
}

.icon-error:before {
  content: '\f132';
}

.icon-expand:before {
  content: '\f133';
}

.icon-export:before {
  content: '\f134';
}

.icon-file-close:before {
  content: '\f135';
}

.icon-file-csv-solid:before {
  content: '\f136';
}

.icon-file-excel-solid:before {
  content: '\f137';
}

.icon-file-lines-solid:before {
  content: '\f138';
}

.icon-file-pdf-solid:before {
  content: '\f139';
}

.icon-file-solid:before {
  content: '\f13a';
}

.icon-file-word-solid:before {
  content: '\f13b';
}

.icon-file-zipped-download:before {
  content: '\f13c';
}

.icon-filter:before {
  content: '\f13d';
}

.icon-flag:before {
  content: '\f13e';
}

.icon-flowgas:before {
  content: '\f13f';
}

.icon-flowing-gas:before {
  content: '\f140';
}

.icon-forms-solid:before {
  content: '\f141';
}

.icon-gas-quality:before {
  content: '\f142';
}

.icon-h-gas-quality:before {
  content: '\f143';
}

.icon-hide:before {
  content: '\f144';
}

.icon-high-demand:before {
  content: '\f145';
}

.icon-home:before {
  content: '\f146';
}

.icon-id-card:before {
  content: '\f147';
}

.icon-index-of-customers:before {
  content: '\f148';
}

.icon-info-post:before {
  content: '\f149';
}

.icon-info:before {
  content: '\f14a';
}

.icon-infopost-capacity:before {
  content: '\f14b';
}

.icon-infopost-downloads:before {
  content: '\f14c';
}

.icon-infopost-form:before {
  content: '\f14d';
}

.icon-infopost-forms:before {
  content: '\f14e';
}

.icon-infopost-gas-quality:before {
  content: '\f14f';
}

.icon-infopost-index-of-customer:before {
  content: '\f150';
}

.icon-infopost-location:before {
  content: '\f151';
}

.icon-infopost-notices:before {
  content: '\f152';
}

.icon-infopost-search:before {
  content: '\f153';
}

.icon-infopost-serach:before {
  content: '\f154';
}

.icon-infopost-standard-of-conduct:before {
  content: '\f155';
}

.icon-infopost-tariff:before {
  content: '\f156';
}

.icon-infopost-transaction-report:before {
  content: '\f157';
}

.icon-information:before {
  content: '\f158';
}

.icon-informational-posting:before {
  content: '\f159';
}

.icon-informational-postings:before {
  content: '\f15a';
}

.icon-invoicing:before {
  content: '\f15b';
}

.icon-key:before {
  content: '\f15c';
}

.icon-leave:before {
  content: '\f15d';
}

.icon-left:before {
  content: '\f15e';
}

.icon-line:before {
  content: '\f15f';
}

.icon-link-line:before {
  content: '\f160';
}

.icon-link-solid:before {
  content: '\f161';
}

.icon-load-template:before {
  content: '\f162';
}

.icon-location:before {
  content: '\f163';
}

.icon-locked-view:before {
  content: '\f164';
}

.icon-locked:before {
  content: '\f165';
}

.icon-logout:before {
  content: '\f166';
}

.icon-low-demand:before {
  content: '\f167';
}

.icon-medium-demand:before {
  content: '\f168';
}

.icon-move-cursor:before {
  content: '\f169';
}

.icon-move-horiz:before {
  content: '\f16a';
}

.icon-navigation:before {
  content: '\f16b';
}

.icon-nominations:before {
  content: '\f16c';
}

.icon-notices:before {
  content: '\f16d';
}

.icon-notifications:before {
  content: '\f16e';
}

.icon-open-new:before {
  content: '\f16f';
}

.icon-oval:before {
  content: '\f170';
}

.icon-overflow-horizontal:before {
  content: '\f171';
}

.icon-overflow-vertical:before {
  content: '\f172';
}

.icon-override:before {
  content: '\f173';
}

.icon-paper-plane:before {
  content: '\f174';
}

.icon-party:before {
  content: '\f175';
}

.icon-pause:before {
  content: '\f176';
}

.icon-pending-filings-solid:before {
  content: '\f177';
}

.icon-pipeline-info:before {
  content: '\f178';
}

.icon-pipeline-model:before {
  content: '\f179';
}

.icon-play:before {
  content: '\f17a';
}

.icon-posted-imbalance:before {
  content: '\f17b';
}

.icon-price:before {
  content: '\f17c';
}

.icon-properties:before {
  content: '\f17d';
}

.icon-rates-discounts:before {
  content: '\f17e';
}

.icon-reduced:before {
  content: '\f17f';
}

.icon-regulatory-new:before {
  content: '\f180';
}

.icon-regulatory:before {
  content: '\f181';
}

.icon-reject:before {
  content: '\f182';
}

.icon-reload:before {
  content: '\f183';
}

.icon-reports:before {
  content: '\f184';
}

.icon-reput-award:before {
  content: '\f185';
}

.icon-right:before {
  content: '\f186';
}

.icon-scale-unbalanced-solid:before {
  content: '\f187';
}

.icon-search:before {
  content: '\f188';
}

.icon-setup:before {
  content: '\f189';
}

.icon-shield-user:before {
  content: '\f18a';
}

.icon-short-term-marketplace:before {
  content: '\f18b';
}

.icon-simple-arrow-left:before {
  content: '\f18c';
}

.icon-simple-arrow-right:before {
  content: '\f18d';
}

.icon-sitemap:before {
  content: '\f18e';
}

.icon-sort:before {
  content: '\f18f';
}

.icon-stratton-gas-composition:before {
  content: '\f190';
}

.icon-success:before {
  content: '\f191';
}

.icon-system-planning:before {
  content: '\f192';
}

.icon-thumbs-up:before {
  content: '\f193';
}

.icon-transaction-report:before {
  content: '\f194';
}

.icon-truck-summary:before {
  content: '\f195';
}

.icon-unlocked:before {
  content: '\f196';
}

.icon-user:before {
  content: '\f197';
}

.icon-view-lock:before {
  content: '\f198';
}

.icon-view:before {
  content: '\f199';
}

.icon-warning:before {
  content: '\f19a';
}

.icon-withdraw-bid:before {
  content: '\f19b';
}

.icon-withdraw:before {
  content: '\f19c';
}

.icon-write:before {
  content: '\f19d';
}
